(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/focus-image/assets/javascripts/render-component.js') >= 0) return;  svs.modules.push('/components/banner_layouts/focus-image/assets/javascripts/render-component.js');
"use strict";

function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { _defineProperty(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; }
function _defineProperty(e, r, t) { return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, { value: t, enumerable: !0, configurable: !0, writable: !0 }) : e[r] = t, e; }
function _toPropertyKey(t) { var i = _toPrimitive(t, "string"); return "symbol" == typeof i ? i : i + ""; }
function _toPrimitive(t, r) { if ("object" != typeof t || !t) return t; var e = t[Symbol.toPrimitive]; if (void 0 !== e) { var i = e.call(t, r || "default"); if ("object" != typeof i) return i; throw new TypeError("@@toPrimitive must return a primitive value."); } return ("string" === r ? String : Number)(t); }

const isServer = typeof exports === 'object';
let formatImages;
let getCallToAction;
if (isServer) {
  const trinidad = require('trinidad-core').core;
  formatImages = trinidad.components.require('banner_common', 'banner-helpers').images.formatImages;
  getCallToAction = trinidad.components.require('banner_common', 'banner-helpers').getCallToAction;
} else {
  formatImages = svs.banner_common.banner_helpers.formatImages;
  getCallToAction = svs.banner_common.banner_helpers.getCallToAction;
}

async function renderComponent(model, widgetInfo, position, req, res) {
  var _model$images, _res$locals, _svs$core, _model$contentType;
  let hbsCompiled;
  let format;
  let isAdmin;
  const formattedImages = ((_model$images = model.images) === null || _model$images === void 0 ? void 0 : _model$images.length) && (await formatImages(model, 'typeName'));
  const features = isServer ? (res === null || res === void 0 || (_res$locals = res.locals) === null || _res$locals === void 0 ? void 0 : _res$locals.features) || [] : ((_svs$core = svs.core) === null || _svs$core === void 0 || (_svs$core = _svs$core.data) === null || _svs$core === void 0 ? void 0 : _svs$core.features) || [];
  if (isServer) {
    const trinidad = require('trinidad-core').core;
    const hbs = global.internalInstances.get('hbs');
    hbsCompiled = hbs.cache['components-banner_layouts-focus_image-background'];
    format = trinidad.components.require('utils', 'format').api;
    isAdmin = req.userSession.hasRole(req.userSession.roles.INTERNAL);
  } else {
    hbsCompiled = svs.banner_layouts.focus_image.templates.background;
    format = svs.utils.format;
    isAdmin = svs.core.userSession.hasRole(svs.core.userSession.roles.INTERNAL);
  }
  const getNoScriptUrl = fallbackImg => fallbackImg === null || fallbackImg === void 0 ? void 0 : fallbackImg.url;
  const defaultThemeClass = 'default-transparent';
  const themeClass = model.layoutConfig.theme || defaultThemeClass;
  const actionViewConfig = {
    inverted: themeClass === defaultThemeClass
  };
  const callToActions = [...model.callToActions].map(action => {
    const callToAction = getCallToAction(action, actionViewConfig);
    return callToAction;
  });
  const trackingStringTitle = format.safeString(model.title) || model.title;
  const contentType = (model === null || model === void 0 || (_model$contentType = model.contentType) === null || _model$contentType === void 0 ? void 0 : _model$contentType.split(':').pop()) || 'banner';
  const el = hbsCompiled(_objectSpread(_objectSpread({}, model.layoutConfig), {}, {
    features,
    images: formattedImages,
    noscriptUrl: formattedImages !== null && formattedImages !== void 0 && formattedImages.productImage_WIDE ? getNoScriptUrl(formattedImages === null || formattedImages === void 0 ? void 0 : formattedImages.productImage_WIDE) : getNoScriptUrl(formattedImages === null || formattedImages === void 0 ? void 0 : formattedImages.productImage),
    isFullWidth: true,
    bannerBackgroundButton: 'btn-inverted',
    callToAction: callToActions,
    crm: model.targetOffer || '',
    bannerId: model.id,
    clickTrackingString: "".concat(widgetInfo.widgetType, "/").concat(widgetInfo.trackingId, "/").concat(position, "/").concat(model.layoutType, "/").concat(trackingStringTitle),
    impressionTrackingString: "".concat(widgetInfo.widgetType, "/").concat(widgetInfo.trackingId, "/").concat(position, "/").concat(model.layoutType, "/").concat(trackingStringTitle),
    contentType,
    themeClass: model.layoutConfig.theme || 'default-transparent',
    isAdmin
  })) || '';
  return el;
}
if (isServer) {
  module.exports = {
    renderComponent
  };
} else {
  svs.banner_layouts.focus_image.renderComponent = renderComponent;
}

 })(window);