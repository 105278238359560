(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/banner_layouts/focus-image/views/background.js') >= 0) return;  svs.modules.push('/components/banner_layouts/focus-image/views/background.js');
(function(svs, Handlebars) {
_svs=window.svs=window.svs||{};
_svs.banner_layouts=_svs.banner_layouts||{};
_svs.banner_layouts.focus_image=_svs.banner_layouts.focus_image||{};
_svs.banner_layouts.focus_image.templates=_svs.banner_layouts.focus_image.templates||{};
svs.banner_layouts.focus_image.templates.background = Handlebars.template({"1":function(container,depth0,helpers,partials,data) {
    return "no-action";
},"3":function(container,depth0,helpers,partials,data) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression((lookupProperty(helpers,"renderCrmAttributes")||(depth0 && lookupProperty(depth0,"renderCrmAttributes"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"crm") : depth0),{"name":"renderCrmAttributes","hash":{},"data":data,"loc":{"start":{"line":6,"column":13},"end":{"line":6,"column":40}}}));
},"5":function(container,depth0,helpers,partials,data) {
    var helper, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return container.escapeExpression(((helper = (helper = lookupProperty(helpers,"themeClass") || (depth0 != null ? lookupProperty(depth0,"themeClass") : depth0)) != null ? helper : container.hooks.helperMissing),(typeof helper === "function" ? helper.call(depth0 != null ? depth0 : (container.nullContext || {}),{"name":"themeClass","hash":{},"data":data,"loc":{"start":{"line":9,"column":80},"end":{"line":9,"column":94}}}) : helper)));
},"7":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-multi_image"),depth0,{"name":"components-content-image_v2-multi_image","hash":{"noscriptUrl":(depth0 != null ? lookupProperty(depth0,"noscriptUrl") : depth0),"altText":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_DEFAULT") : stack1)) != null ? lookupProperty(stack1,"altText") : stack1),"title":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage") : stack1)) != null ? lookupProperty(stack1,"title") : stack1),"images":(lookupProperty(helpers,"array")||(depth0 && lookupProperty(depth0,"array"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_DEFAULT") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_BACKGROUND") : stack1),((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_BANNER_MOBILE") : stack1),{"name":"array","hash":{},"data":data,"loc":{"start":{"line":13,"column":20},"end":{"line":13,"column":121}}})},"data":data,"indent":"      ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "");
},"9":function(container,depth0,helpers,partials,data) {
    var stack1, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"themeClass") : depth0),"!==","default-transparent",{"name":"compare","hash":{},"fn":container.program(10, data, 0),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":79},"end":{"line":37,"column":172}}})) != null ? stack1 : "");
},"10":function(container,depth0,helpers,partials,data) {
    return "banner-focusimage-cta-inverted";
},"12":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "                    "
    + container.escapeExpression((lookupProperty(helpers,"dynamicActionTemplate")||(depth0 && lookupProperty(depth0,"dynamicActionTemplate"))||container.hooks.helperMissing).call(depth0 != null ? depth0 : (container.nullContext || {}),(depth0 != null ? lookupProperty(depth0,"actionType") : depth0),(depth0 != null ? lookupProperty(depth0,"actionConfig") : depth0),(depths[1] != null ? lookupProperty(depths[1],"clickTrackingString") : depths[1]),{"name":"dynamicActionTemplate","hash":{},"data":data,"loc":{"start":{"line":39,"column":20},"end":{"line":39,"column":92}}}))
    + "\n";
},"compiler":[8,">= 4.3.0"],"main":function(container,depth0,helpers,partials,data,blockParams,depths) {
    var stack1, helper, alias1=depth0 != null ? depth0 : (container.nullContext || {}), alias2=container.hooks.helperMissing, alias3="function", alias4=container.escapeExpression, lookupProperty = container.lookupProperty || function(parent, propertyName) {
        if (Object.prototype.hasOwnProperty.call(parent, propertyName)) {
          return parent[propertyName];
        }
        return undefined
    };

  return "\n<div class=\"banner f-content banner-focusimage background-banner-type\n  "
    + ((stack1 = (lookupProperty(helpers,"compare")||(depth0 && lookupProperty(depth0,"compare"))||alias2).call(alias1,((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"callToAction") : depth0)) != null ? lookupProperty(stack1,"0") : stack1)) != null ? lookupProperty(stack1,"actionType") : stack1),"===","no-action",{"name":"compare","hash":{},"fn":container.program(1, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":3,"column":2},"end":{"line":3,"column":81}}})) != null ? stack1 : "")
    + "\"\n  data-cmsid=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"bannerId") || (depth0 != null ? lookupProperty(depth0,"bannerId") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"bannerId","hash":{},"data":data,"loc":{"start":{"line":4,"column":14},"end":{"line":4,"column":26}}}) : helper)))
    + "\"\n  data-cmstype=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"contentType") || (depth0 != null ? lookupProperty(depth0,"contentType") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"contentType","hash":{},"data":data,"loc":{"start":{"line":5,"column":16},"end":{"line":5,"column":31}}}) : helper)))
    + "\"\n  "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"crm") : depth0),{"name":"if","hash":{},"fn":container.program(3, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":6,"column":2},"end":{"line":6,"column":47}}})) != null ? stack1 : "")
    + "\n  data-impression=\"true\"\n  data-impression-tracking=\""
    + alias4(((helper = (helper = lookupProperty(helpers,"impressionTrackingString") || (depth0 != null ? lookupProperty(depth0,"impressionTrackingString") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"impressionTrackingString","hash":{},"data":data,"loc":{"start":{"line":8,"column":28},"end":{"line":8,"column":56}}}) : helper)))
    + "\">\n  <div class=\"banner-focusimage-container f-content f-content-"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"themeClass") : depth0),{"name":"if","hash":{},"fn":container.program(5, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":9,"column":62},"end":{"line":9,"column":101}}})) != null ? stack1 : "")
    + "\">\n  <div class=\"banner-bg-image\">\n"
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"productImage_DEFAULT") : stack1),{"name":"if","hash":{},"fn":container.program(7, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":11,"column":4},"end":{"line":18,"column":11}}})) != null ? stack1 : "")
    + "    </div>\n    <div class=\"banner-focusimage-overlay\">\n          <div class=\"grid-row banner-center-image\">\n            <div class=\"col-xs-12 col-sm-offset-1 col-sm-10  col-am-offset-2 col-am-8 col-md-offset-3 col-md-6\">\n"
    + ((stack1 = container.invokePartial(lookupProperty(partials,"components-content-image_v2-image"),((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"centerImage") : stack1),{"name":"components-content-image_v2-image","hash":{"dominantColor":"transparent","title":((stack1 = ((stack1 = (depth0 != null ? lookupProperty(depth0,"images") : depth0)) != null ? lookupProperty(stack1,"centerImage") : stack1)) != null ? lookupProperty(stack1,"altText") : stack1)},"data":data,"indent":"            ","helpers":helpers,"partials":partials,"decorators":container.decorators})) != null ? stack1 : "")
    + "         </div>\n         </div>\n      <div class=\"banner-focusimage-content banner-focusimage-content-centered\">\n        <div class=\"banner-focusimage-content-container margin-xs-top-1 margin-md-top-2 margin-md-bottom-4 margin-xs-bottom-3\">\n          <div class=\"banner-focusimage-content-holder\">\n            <section>\n               <div class=\"banner-focusimage-textarea-background\">\n                 <h1 class=\"banner-focusimage-title\" itemprop=\"headline\">\n                   "
    + alias4(((helper = (helper = lookupProperty(helpers,"header") || (depth0 != null ? lookupProperty(depth0,"header") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"header","hash":{},"data":data,"loc":{"start":{"line":32,"column":19},"end":{"line":32,"column":29}}}) : helper)))
    + "\n                 </h1>\n                 <div class=\"margin-xs-bottom-1 f-summary\" itemprop=\"text\">\n                   <p class=\"banner-focusimage-paragraph\">"
    + alias4(((helper = (helper = lookupProperty(helpers,"body") || (depth0 != null ? lookupProperty(depth0,"body") : depth0)) != null ? helper : alias2),(typeof helper === alias3 ? helper.call(alias1,{"name":"body","hash":{},"data":data,"loc":{"start":{"line":35,"column":58},"end":{"line":35,"column":66}}}) : helper)))
    + "</p>\n                 </div>\n                 <div class=\"banner-focusimage-cta-container "
    + ((stack1 = lookupProperty(helpers,"if").call(alias1,(depth0 != null ? lookupProperty(depth0,"themeClass") : depth0),{"name":"if","hash":{},"fn":container.program(9, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":37,"column":61},"end":{"line":37,"column":179}}})) != null ? stack1 : "")
    + "\">\n"
    + ((stack1 = lookupProperty(helpers,"each").call(alias1,(depth0 != null ? lookupProperty(depth0,"callToAction") : depth0),{"name":"each","hash":{},"fn":container.program(12, data, 0, blockParams, depths),"inverse":container.noop,"data":data,"loc":{"start":{"line":38,"column":18},"end":{"line":40,"column":27}}})) != null ? stack1 : "")
    + "                 </div>\n              </div>\n            </section>\n          </div>\n        </div>\n      </div>\n    </div>\n  </div>\n</div>\n\n";
},"usePartial":true,"useData":true,"useDepths":true});
Handlebars.partials['components-banner_layouts-focus_image-background'] = svs.banner_layouts.focus_image.templates.background;
})(svs, Handlebars);


 })(window);